define("discourse/plugins/automation/admin/components/fields/da-user-field", ["exports", "@ember/helper", "@ember/object", "discourse-i18n", "select-kit/components/user-chooser", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _object, _discourseI18n, _userChooser, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserField extends _daBaseField.default {
    onChangeUsername(usernames) {
      this.mutValue(usernames[0]);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChangeUsername", [_object.action]))();
    modifyContent(field, content) {
      content = field.acceptedContexts.map(context => {
        return {
          name: (0, _discourseI18n.i18n)(`discourse_automation.scriptables.${field.targetName}.fields.${field.name}.${context}_context`),
          username: context
        };
      }).concat(content);
      return content;
    }
    static #_2 = (() => dt7948.n(this.prototype, "modifyContent", [_object.action]))();
    static #_3 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field user-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <UserChooser
                @value={{@field.metadata.value}}
                @onChange={{this.onChangeUsername}}
                @modifyContent={{fn this.modifyContent @field}}
                @options={{hash
                  maximum=1
                  excludeCurrentUser=false
                  disabled=@field.isDisabled
                }}
              />
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "aINOlA36",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field user-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@modifyContent\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"onChangeUsername\"]],[28,[32,2],[[30,0,[\"modifyContent\"]],[30,2]],null],[28,[32,3],null,[[\"maximum\",\"excludeCurrentUser\",\"disabled\"],[1,false,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/srv/www/vhosts/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-user-field.js",
      "scope": () => [_daFieldLabel.default, _userChooser.default, _helper.fn, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserField;
});